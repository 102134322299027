import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { useTranslation } from 'react-i18next';
import GTMData from '@square-enix-private/fe-gtm/dist/GTMData';
import { Flex } from '@square-enix-private/vaquita-ui';
import VideosViewer from '~/components/VideosListViewer';

import SectionTitle from '~/components/SectionTitle/index';

const usePlaceholders = () => {
  const { t } = useTranslation('broadcastplaceholders');
  const title = t('title');
  const placeholder = t('placeholders', { returnObjects: true });

  return placeholder.map((item) => ({ ...item, title }));
};

const BroadcastPage = ({ pageContext: { locale } }) => {
  const { t } = useTranslation('broadcastpage');
  const broadcastVideos = useTranslation('broadcastitems').t('content', {
    returnObjects: true
  });

  const placeholders = usePlaceholders();

  return (
    <Flex
      width={1}
      maxWidth="1400px"
      m="auto"
      alignItems="center"
      flexDirection="column"
      pt={['xlarge', 'xxlarge']}
      pb={[null, null, 'xlarge']}
      px={['medium', 'large']}
    >
      <Flex
        width={1}
        justifyContent={['center', 'left']}
        mb={[0, 'medium', 'large']}
      >
        <SectionTitle title={t('title')} />
      </Flex>
      <GTMData pageId="broadcastPage" breadcrumb="broadcastPage" />
      <Flex width={1} mb="medium">
        <VideosViewer
          items={broadcastVideos}
          placeholders={placeholders}
          locale={locale}
        />
      </Flex>
    </Flex>
  );
};

BroadcastPage.propTypes = {
  pageContext: PropTypes.object
};

BroadcastPage.defaultProps = {
  pageContext: {}
};

export default BroadcastPage;

export const query = graphql`
  query($locale: String) {
    ...FooterData
    ...GenericData
    ...MetadataData
    ...FlagsData
    ...MenuData
    ...AssetsData
    ...AgeGateData
    broadcastpage(filter: { localeDirectory: { eq: $locale } }) {
      title
    }
    broadcastitems(filter: { localeDirectory: { eq: $locale } }) {
      slug
      id
      title
      video {
        title
        videoURL
      }
      video {
        alt
        image {
          ...FastlyProps
        }
      }
    }
    broadcastplaceholders(filter: { localeDirectory: { eq: $locale } }) {
      title
      slug
      placeholders {
        id
        alt
        image {
          ...FastlyProps
        }
      }
    }
  }
`;
